import React from 'react';

const SearchField = ({handleSearch, searchRequest, setSearchRequest}) => {
    return (
        <form onSubmit={handleSearch} className="mr-2 border">
            <input
                type="text"
                value={searchRequest}
                onChange={e => setSearchRequest(e.target.value)}
                placeholder="Search"
                className="input w-24 md:w-auto"
            />
            <button type="submit" className="btn btn-ghost">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                </svg>
            </button>
        </form>
    );
};

export default SearchField;
